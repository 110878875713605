<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="filter" url="homeUser">
      <template slot="adSearch">
        <vm-search label="微信openId">
          <el-input v-model.trim="filter.openId" placeholder="openId" clearable></el-input>
        </vm-search>
        <vm-search label="微信unionId">
          <el-input v-model.trim="filter.unionId" placeholder="unionId" clearable></el-input>
        </vm-search>
        <vm-search label="电梯Id">
          <el-input v-model.trim="filter.elevatorId" placeholder="elevatorId" clearable></el-input>
        </vm-search>
      </template>
      <table-column prop="openId" label="微信openId"></table-column>
      <table-column prop="unionId" label="微信unionId"></table-column>
      <table-column prop="elevatorId" label="电梯Id" :width="100" align="center"></table-column>
      <table-column prop="createTime" label="创建时间" :width="140" align="center"></table-column>
      <table-column prop="loginTime" label="登录时间" :width="140" align="center"></table-column>
      <table-column label="操作" align="center" :width="80" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ "删除" }}</el-button>
        </template>
      </table-column>
    </vm-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      filter: {
        openId: "",
        unionId: "",
        elevatorId: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm("删除 电梯Id " + row.elevatorId + ",确定删除吗?", { type: "warning" }).then(() => {
        this.$http.delete("homeUser", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
